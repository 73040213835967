import React, { useState, useEffect } from 'react'
import { Input, FormCustom, SwitchToggle, Select, TextArea } from '@components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { useSelector } from 'react-redux'

const MaintenanceForm = ({ data, dataStart, onSubmit, submitIsCorrect, submitCustom, loadSubmit, module, tableFieldsAdd, append, beforeRenderForm, appendAfterForm, removeEnabled, removeDescription, inputDescriptionOptions, formCustomOptions, prepend }) => {
    const store = useSelector(store => store)
    const [state, setState] = useState({})
    const [stateDynamic, setStateDynamic] = useState({})

    const [row, setRow] = useState({})
    const [ready, setReady] = useState(false)

    const [hasErrorForm, setHasErrorForm] = useState(false)

    const [execValidation, setExecValidation] = useState(true)

    useEffect(_ => {
        const dataInit = [
            { name: 'id_' + module, value: '' },
            { name: 'description_' + module, value: '' }
        ]
        if (!removeEnabled) {
            dataInit.push({ name: 'enabled_' + module, value: 1 })
        }
        dataStart.forEach(s => dataInit.push(s))

        if (!data['id_' + module]) {
            let dInit = {}
            dataInit.forEach(p => {
                dInit['p_' + p.name] = data.hasOwnProperty(p.name) ? data[p.name] : p.value
            })
            setRow(dInit)
        } else {
            let dInit = {}
            for (let k in data) {
                dInit['p_' + k] = data[k]
            }
            setRow(dInit)
        }
    }, [data, dataStart, module, removeEnabled])

    useEffect(_ => {
        beforeRenderForm({
            ready: _ => setReady(true),
            state,
            setState: d => setState(d),
            setStateDynamic: d => setStateDynamic(d),
            stateDynamic,
            data,
            row,
            setRow,
        })
    }, [])

    const renderSelect = ({ text, name, nameSelect, label = '', addParams = '', options = {} } = {}) => {
        return <Select
            showFeedback={hasErrorForm}
            text={text}
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            invalid="El campo es obligatorio."
            value={_ => {
                if (!row['p_' + name]) {
                    return
                }

                let finded = state[nameSelect]
                    .find(st => parseInt(row['p_' + name]) === parseInt(st['id_' + nameSelect]))

                if (!finded) {
                    return
                }

                let params = {}
                if (addParams) {
                    params = addParams({ item: finded })
                }

                return {
                    label: label ? label({ item: finded }) : finded['description_' + nameSelect] + (finded['code_' + nameSelect] ? ` (${finded['code_' + nameSelect]})` : ''),
                    value: finded[name],
                    ...params
                }
            }}
            options={_ => {
                if (state[nameSelect]) {
                    return state[nameSelect].map(c => {
                        let params = {}
                        if (addParams) {
                            params = addParams({ item: c })
                        }
                        return {
                            value: c['id_' + nameSelect],
                            label: label ? label({ item: c }) : c['description_' + nameSelect] + (c['code_' + nameSelect] ? ` (${c['code_' + nameSelect]})` : ''),
                            ...params
                        }
                    })
                }

                return []
            }}
            onChange={e => {
                let selectedItem = {}
                selectedItem['p_' + name] = e.value
                setRow({ ...row, ...selectedItem })
            }}
            {...options}
        />
    }

    const renderInput = ({ text, name, options = {} } = {}) => {
        return <>
            <Input
                type={options?.type ? options.type : 'text'}
                text={text}
                placeholder={text}
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                invalid="El campo es obligatorio."
                value={row['p_' + name]}
                onChange={e => {
                    let r = { ...row }
                    r['p_' + name] = e.currentTarget.value
                    setRow(r)
                }}
                {...options}
            />
        </>
    }

    const renderTextArea = ({ text, name, options = {} } = {}) => {
        return <>
            <TextArea
                type={options?.type ? options.type : 'text'}
                text={text}
                placeholder={text}
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                invalid="El campo es obligatorio."
                value={row['p_' + name]}
                onChange={e => {
                    let r = { ...row }
                    r['p_' + name] = e.currentTarget.value
                    setRow(r)
                }}
                {...options}
            />
        </>
    }

    const renderSwitch = ({ name, options = {} } = {}) => {
        return <>
            <SwitchToggle
                classNameParent="col-12 mb-2"
                id={name + 'check'}
                checked={parseInt(row['p_' + name]) === 1}
                onChange={e => {
                    let r = { ...row }
                    r['p_' + name] = e.currentTarget.checked ? 1 : 0
                    setRow(r)
                }}
                {...options}
            />
        </>
    }

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={10}>
        <FormCustom execValidation={execValidation} dataSubmit={row} onSubmit={({ e, data, hasError }) => {
            setHasErrorForm(hasError)
            if (hasError) {
                return
            }

            let allData = data
            if (submitCustom) {
                allData = submitIsCorrect({ e, data, stateDynamic, state, row });
                if (allData === false) {
                    return false;
                }
            }

            onSubmit({ e, data: allData })
        }} loadSubmit={loadSubmit} {...(formCustomOptions({ data }))}>
            {prepend({
                row,
                setRow,
                state,
                setState: d => setState(d),
                setStateDynamic: d => setStateDynamic(d),
                stateDynamic,
                renderSelect,
                renderInput,
                renderTextArea,
                renderSwitch,
                execValidation, setExecValidation,
                store,
                data
            })}
            {!removeDescription ? <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                value={row['p_description_' + module]}
                onChange={e => {
                    let r = { ...row }
                    r['p_description_' + module] = e.currentTarget.value
                    setRow(r)
                }}
                maxLength={150}
                {...inputDescriptionOptions}
            /> : ''}
            {React.Children.toArray(tableFieldsAdd.filter(t => t.hideOnForm !== true).map(t => {
                let fnChange = t?.onChange ? (data) => {
                    t?.onChange(data)
                } : e => {
                    let r = { ...row }
                    r['p_' + t.name] = e.currentTarget.value
                    setRow(r)
                }

                if (t.type === 'input') {
                    return <>
                        {t?.renderBefore ? t.renderBefore({ row, stateDynamic, setStateDynamic }) : ''}
                        {renderInput(t)}
                        {t?.renderAfter ? t.renderAfter({ row, stateDynamic, setStateDynamic }) : ''}
                    </>
                } else if (t.type === 'textarea') {
                    return <>
                        {t?.renderBefore ? t.renderBefore({ row, stateDynamic, setStateDynamic }) : ''}
                        {renderTextArea(t)}
                        {t?.renderAfter ? t.renderAfter({ row, stateDynamic, setStateDynamic }) : ''}
                    </>
                } else if (t.type === 'select') {
                    return <>
                        {t?.renderBefore ? t.renderBefore({ row, stateDynamic, setStateDynamic }) : ''}
                        {renderSelect(t)}
                        {t?.renderAfter ? t.renderAfter({ row, stateDynamic, setStateDynamic }) : ''}
                    </>
                } else if (t.type === 'check') {
                    return <>
                        {t?.renderBefore ? t.renderBefore({ row, stateDynamic, setStateDynamic }) : ''}
                        {renderSwitch(t)}
                        {t?.renderAfter ? t.renderAfter({ row, stateDynamic, setStateDynamic }) : ''}
                    </>
                } else if (t.type === 'file') {
                    return <>
                        {t?.renderBefore ? t.renderBefore({ row, stateDynamic, setStateDynamic }) : ''}
                        <Input
                            classNameParent="col-12 mb-2"
                            type="file"
                            onChange={e => {
                                fnChange({ e, stateDynamic, setStateDynamic })
                            }}
                            invalid="El campo es obligatorio."
                            required={row['p_' + t.name] === ''}
                            {...t.options}
                        />
                        {t?.renderAfter ? t.renderAfter({ row, stateDynamic, setStateDynamic }) : ''}
                    </>
                }
                return ''
            }))}
            {removeEnabled ? '' : <SwitchToggle
                classNameParent="col-12 mb-2"
                id={module + '-enabled'}
                defaultChecked={parseInt(row['p_enabled_' + module]) === 1}
                onChange={e => {
                    let r = { ...row }
                    r['p_enabled_' + module] = e.currentTarget.checked ? 1 : 0
                    setRow(r)
                }}
            />}
            {append({
                row,
                setRow,
                state,
                setState: d => setState(d),
                setStateDynamic: d => setStateDynamic(d),
                stateDynamic,
                renderSelect,
                renderInput,
                renderTextArea,
                renderSwitch,
                execValidation, setExecValidation,
                store,
                data
            })}
        </FormCustom>
        {appendAfterForm({
            row,
            setRow,
            state,
            setState: d => setState(d),
            setStateDynamic: d => setStateDynamic(d),
            stateDynamic,
            renderSelect,
            renderInput,
            renderTextArea,
            renderSwitch,
            execValidation, setExecValidation,
            store,
            data
        })}
    </ReactPlaceholder>
}

MaintenanceForm.defaultProps = {
    data: {
    },
    onSubmit: () => { },
    loadSubmit: false,
    append: _ => { },
    prepend: _ => { },
    submitIsCorrect: _ => true,
    submitCustom: false,
    beforeRenderForm: ({ ready }) => ready(),
    appendAfterForm: _ => { },
    dataStart: [],
    removeEnabled: false,
    inputDescriptionOptions: {},
    removeDescription: false,
    formCustomOptions: _ => {
        return {}
    }
}

export default MaintenanceForm;