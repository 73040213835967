import React, { useEffect, useState } from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { SwitchToggle, Select, Input, FormCustom } from '@components/Form/Form';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/esm/Card';
import ReactPlaceholder from 'react-placeholder'
import { Bar } from 'react-chartjs-2';

const Dashboard = _ => {
    const [dates, setDates] = useState({
        p_date_start: Helpers.date.get({ format: '%Y-%m-%d', addDays: -7 }),
        p_date_end: Helpers.date.get({ format: '%Y-%m-%d' }),
    })
    const [saleDaily, setSaleDaily] = useState({})
    const [saleTotal, setSaleTotal] = useState({})
    const [saleWorker, setSaleWorker] = useState([])
    const [saleProduct, setSaleProduct] = useState([])
    const [saleAverage, setSaleAverage] = useState({})
    const [ready, setReady] = useState(false)

    useEffect(_ => {
        if (!ready) {
            Promise.all([
                Controllers.dashboard.get_sale_daily(),
                Controllers.dashboard.get_sale_total(dates),
                Controllers.dashboard.get_sale_worker(dates),
                Controllers.dashboard.get_sale_product(dates),
                Controllers.dashboard.get_sale_average(dates),
            ]).then(res => {
                setSaleDaily(res[0].data)
                setSaleTotal(res[1].data)
                setSaleWorker(res[2].data)
                setSaleProduct(res[3].data)
                setSaleAverage(res[4].data)
                setReady(true)
            })
        }
    }, [dates, ready])


    const chartWorker = _ => {
        let workers = []
        let total = []
        saleWorker?.forEach(s => {
            workers.push(s.name_worker)
            total.push(s.total)
        })
        return {
            labels: workers,
            datasets: [
                {
                    label: 'Ventas',
                    data: total,
                    backgroundColor: '#F26122',
                }
            ],
        }
    }

    const totalWorkerSales = _ => {
        let total = 0
        saleWorker?.forEach(s => {
            total += parseFloat(s.total)
        })
        return Helpers.number.withCommas({
            number: total.toFixed(2)
        })
    }

    const workerSaleTop = _ => {
        let workerTop = {
            name_worker: '',
            total: ''
        }
        if (saleWorker?.find(s => s)) {
            let w = saleWorker?.find(s => s)
            workerTop = {
                name_worker: w.name_worker,
                total:Helpers.number.withCommas({
                    number: w.total
                })
            }
            
        }
        return workerTop
    }

    const chartProduct = _ => {
        let products = []
        let total = []
        saleProduct?.forEach(s => {
            products.push(s.description_product)
            total.push(s.total)
        })
        return {
            labels: products,
            datasets: [
                {
                    label: 'Cantidad Vendidas',
                    data: total,
                    backgroundColor: '#42BDA1',
                },
            ],
        }
    }

    const totalProductSale = _ => {
        let total = 0
        saleProduct?.forEach(s => {
            total += parseFloat(s.total)
        })
        return total
    }

    const productSaleTop = _ => {
        let productTop = {
            description_product: '',
            total: ''
        }
        if (saleProduct?.find(s => s)) {
            productTop = saleProduct?.find(s => s)
            productTop.total = parseInt(productTop.total)
        }
        return productTop
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return <div className="mt-4">
        <div className="form-row">
            <div className="col-12 mb-4">
                <Card>
                    <Card.Body>
                        <small className="font-weight-bold">Filtar por Fechas</small>
                        <FormCustom viewSubmit={false} onSubmit={({hasError}) => {
                            if (!hasError) {
                                setReady(false)
                            }
                        }}>
                            <Input
                                type="date"
                                text="Fecha Inicio"
                                classNameParent="col-md-6"
                                value={dates.p_date_start}
                                required
                                onChange={e => {
                                    setDates({ ...dates, p_date_start: e.currentTarget.value })
                                }}
                                size=""
                                disabled={!ready}
                            />
                            <Input
                                type="date"
                                text="Fecha Fin"
                                classNameParent="col-md-6"
                                value={dates.p_date_end}
                                onChange={e => {
                                    setDates({ ...dates, p_date_end: e.currentTarget.value })
                                }}
                                required
                                append={[
                                    <Button type="submit" disabled={!ready} style={{ borderRadius: 10 }}>
                                        {!ready ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-search mr-1"></i> Filtrar</>}
                                    </Button>
                                ]}
                                size=""
                                disabled={!ready}
                            />
                        </FormCustom>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                            <small className="font-weight-bold">PROMEDIO DE VENTAS</small>
                            <div className="mt-2">
                                <h5 className="text-primary"><strong style={{ color: '#F26122' }}>$ {saleAverage.total}</strong></h5>
                                <div style={{
                                    background: 'linear-gradient(118deg, #F26122, #F9870C)',
                                    width: '100%',
                                    height: 4,
                                    borderRadius: 5
                                }}></div>
                                <div className="d-flex mt-1">
                                    {/* <i className="fas fa-caret-up text-success align-self-center mr-1"></i> */}
                                    <small>{/* <strong>4%</strong> */} Promedio</small>
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            {/* <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <small className="font-weight-bold">NÚMERO DE USUARIOS</small>
                        <div className="mt-2">
                            <h5 className="text-primary"><strong style={{ color: '#01AFA4' }}>1,650</strong></h5>
                            <div style={{
                                background: 'linear-gradient(118deg, #01AFA4, #5DE558)',
                                width: '100%',
                                height: 4,
                                borderRadius: 5
                            }}></div>
                            <div className="d-flex mt-1">
                                <i className="fas fa-caret-up text-success align-self-center mr-1"></i>
                                <small><strong>4%</strong> Hoy</small>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div> */}
            <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                            <small className="font-weight-bold">VENTA TOTAL</small>
                            <div className="mt-2">
                                <h5 className="text-primary"><strong style={{ color: '#774EF6' }}>$ {saleTotal.total}</strong></h5>
                                <div style={{
                                    background: 'linear-gradient(118deg, #774EF6, #0BBDF8)',
                                    width: '100%',
                                    height: 4,
                                    borderRadius: 5
                                }}></div>
                                <div className="d-flex mt-1">
                                    {/* <i className="fas fa-caret-up text-success align-self-center mr-1"></i> */}
                                    <small>{/* <strong>4%</strong> */} Total</small>
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-6 col-lg-3 mb-4">
                <Card>
                    <Card.Body>
                        <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                            <small className="font-weight-bold">VENTA DIARIA</small>
                            <div className="mt-2">
                                <h5 className="text-primary"><strong style={{ color: '#BB02CE' }}>$ {saleDaily.total}</strong></h5>
                                <div style={{
                                    background: 'linear-gradient(118deg, #BB02CE, #8F6FF0)',
                                    width: '100%',
                                    height: 4,
                                    borderRadius: 5
                                }}></div>
                                <div className="d-flex mt-1">
                                    {/* <i className="fas fa-caret-up text-success align-self-center mr-1"></i> */}
                                    <small>{/* <strong>4%</strong> */} Hoy</small>
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-12 mb-4">
                <Card>
                    <Card.Header>Ventas por Empleado</Card.Header>
                    <Card.Body>
                        <div className="form-row">
                            <div className="col-md-3">
                                <div className="d-flex flex-column">
                                    <div className="mb-4">
                                        <small>Cantidad de Empleados</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    {saleWorker.length}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div className="mb-4">
                                        <small>Total de Ventas</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    $ {totalWorkerSales()}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div>
                                        <small>Con Más Ventas</small>
                                        <strong className="text-dark">

                                            <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                                                <h3 className="mb-0">
                                                    $ {workerSaleTop().total}
                                                </h3>
                                                <small className="text-primary">{workerSaleTop().name_worker}</small>
                                            </ReactPlaceholder>
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                                    <Bar data={chartWorker()} options={options} />
                                </ReactPlaceholder>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-12">
                <Card>
                    <Card.Header>Ventas por Producto</Card.Header>
                    <Card.Body>
                        <div className="form-row">
                            <div className="col-md-3">
                                <div className="d-flex flex-column">
                                    <div className="mb-4">
                                        <small>Cantidad de Productos</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    {saleProduct.length}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div className="mb-4">
                                        <small>Cantidad Total</small>
                                        <strong className="text-dark">
                                            <h3 className="mb-0">
                                                <ReactPlaceholder ready={ready} rows={1} showLoadingAnimation>
                                                    {totalProductSale()}
                                                </ReactPlaceholder>
                                            </h3>
                                        </strong>
                                    </div>
                                    <div>
                                        <small>Más Vendido</small>
                                        <strong className="text-dark">

                                            <ReactPlaceholder ready={ready} rows={2} showLoadingAnimation>
                                                <h3 className="mb-0">
                                                    {productSaleTop().total}
                                                </h3>
                                                <small className="text-primary">{productSaleTop().description_product}</small>
                                            </ReactPlaceholder>
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <ReactPlaceholder ready={ready} rows={4} showLoadingAnimation>
                                    <Bar data={chartProduct()} options={options} />
                                </ReactPlaceholder>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </div>
}

export default Dashboard;