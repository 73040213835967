import React from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';

const Customers = ({ onlyButton, btnNewOptions, afterInsertUpdate }) => {
    return <>
        <MaintenanceCustom
            btnNewOptions={btnNewOptions}
            onlyButton={onlyButton}
            afterInsertUpdate={afterInsertUpdate}
            title={<>
                <i className="fa fa-list text-secondary mr-2"></i>Listado de Clientes
            </>} namePlural="Clientes" nameSingle="Clientes" separator="el" module="customers"
            optionsView={{
                removeDescription: true,
                dataStart: [
                    { name: 'full_name_customers', value: '' },
                    { name: 'company_name_customers', value: '' },
                    { name: 'id_type_customers', value: '' },
                    { name: 'number_document_customers', value: '' },
                    { name: 'discount_customers', value: 1 },
                    { name: 'phone_customers', value: '' },
                    { name: 'email_customers', value: '' },
                    { name: 'address_customers', value: '' },
                    { name: 'address_shipping_customers', value: '' },
                    { name: 'credit_customers', value: '' },
                    { name: 'note_customers', value: '' },
                    { name: 'enabled_customers', value: 1 },
                ],
                beforeRenderForm: ({ ready, setState }) => {
                    Promise.all([
                        Controllers.type_customers.get_type_customers(),
                        Controllers.country.get_country(),
                    ]).then(res => {
                        setState({
                            type_customers: res[0].data,
                            country: res[1].data,
                        })
                        ready()
                    })
                },
                removeEnabled: true,
                submitCustom: true,
                submitIsCorrect: ({ data }) => {
                    if (!data.p_id_type_customers) {
                        Helpers.toast.warning({ message: 'Seleccionar Tipo Cliente', toast })
                        return false
                    }

                    return data
                },
                prepend: ({ renderInput, row, setRow }) => {
                    return <>
                        {renderInput({
                            text: 'Nombres',
                            name: 'full_name_customers',
                            options: {
                                maxLength: 255,
                                required: row.p_company_name_customers === '' && row.p_full_name_customers === '',
                                classNameParent: 'col-md-6 mb-2',
                                smallColor: 'text-dark font-weight-bold',
                                icon: '',
                                size: '',
                                onChange: e => {
                                    setRow({
                                        ...row,
                                        p_full_name_customers: e.currentTarget.value,
                                        p_company_name_customers: ''
                                    })
                                }
                            }
                        })}
                        {renderInput({
                            text: 'Nombre Empresa',
                            name: 'company_name_customers',
                            options: {
                                maxLength: 255,
                                required: row.p_company_name_customers === '' && row.p_full_name_customers === '',
                                classNameParent: 'col-md-6 mb-2',
                                smallColor: 'text-dark font-weight-bold',
                                icon: '',
                                size: '',
                                onChange: e => {
                                    setRow({
                                        ...row,
                                        p_company_name_customers: e.currentTarget.value,
                                        p_full_name_customers: ''
                                    })
                                }
                            },
                        })}
                    </>
                    /* {
                        name: 'full_name_customers', align: 'center', text: 'Nombres', type: 'input', options: {
                            maxLength: 255,
                            required: true,
                            classNameParent: 'col-md-6 mb-2',
                            smallColor: 'text-dark font-weight-bold',
                            icon: '',
                            size: '',
                        }
                    },
                    {
                        name: 'company_name_customers', align: 'center', text: 'Nombre Empresa', type: 'input', options: {
                            maxLength: 255,
                            required: true,
                            classNameParent: 'col-md-6 mb-2',
                            smallColor: 'text-dark font-weight-bold',
                            icon: '',
                            size: '',
                            size: ''
                        }
                    }, */
                }
            }}
            modalSize="lg"
            removeDescription
            removeEnabled
            tableFieldsAdd={[
                {
                    name: 'full_name_customers', align: 'center', text: 'Nombres',
                    hideOnForm: true
                },
                {
                    name: 'company_name_customers', align: 'center', text: 'Nombre Empresa',
                    hideOnForm: true
                },
                {
                    name: 'id_type_customers', nameSelect: 'type_customers', text: 'Tipo Cliente', type: 'select',
                    options: {
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                        required: true
                    },
                    hideOnTable: true
                },
                {
                    name: 'description_type_customers', align: 'center', text: "Tipo Cliente",
                    hideOnForm: true
                },
                {
                    name: 'number_document_customers', align: 'center', text: 'RFC', type: 'input', options: {
                        maxLength: 20,
                        required: true,
                        classNameParent: 'col-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    }
                },
                /* {
                    name: 'discount_customers', align: 'center', text: 'Descuento', type: 'input', options: {
                        required: true,
                        type: 'number',
                        step: '0.10',
                        min: "0",
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                }, */
                {
                    name: 'phone_customers', align: 'center', text: 'Telefono', type: 'input', options: {
                        maxLength: 20,
                        required: true,
                        classNameParent: 'col-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                        placeholder: '+52'
                    }
                },
                {
                    name: 'email_customers', align: 'center', text: 'Email', type: 'input', options: {
                        maxLength: 150,
                        type: 'email',
                        required: true,
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                        placeholder: '@'
                    }
                },
                {
                    name: 'street_customers', align: 'center', text: 'Calle', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'street_number_customers', align: 'center', text: 'Número', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'street_inside_customers', align: 'center', text: 'Interior', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'sububr_customers', align: 'center', text: 'Colonia', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'zip_code_customers', align: 'center', text: 'Código Postal', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'location_customers', align: 'center', text: 'Localidad', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'municipality_customers', align: 'center', text: 'Municipio', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'department_customers', align: 'center', text: 'Estado', type: 'input', options: {
                        maxLength: 255,
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                    },
                    hideOnTable: true
                },
                {
                    name: 'id_country', nameSelect: 'country', text: 'País', type: 'select',
                    options: {
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: '',
                        size: ''
                    },
                    hideOnTable: true
                },
                {
                    name: 'note_customers', align: 'center', text: 'Comentario', type: 'input', options: {
                        classNameParent: 'col-md-6 mb-2',
                        smallColor: 'text-dark font-weight-bold',
                        icon: '',
                        size: ''
                    },
                    hideOnTable: true
                },
            ]}
        />
    </>
}

Customers.defaultProps = {
    onlyButton: false,
    btnNewOptions: {},
    afterInsertUpdate: _ => { }
}

export default Customers;