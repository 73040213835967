import axios from 'axios'

const Helpers = {}

Helpers.config = {
    isProduction: _ => process.env.NODE_ENV === 'production',
    companyName: _ => process.env.NODE_ENV === 'production' ? window.companyName : process.env.REACT_APP_COMPANY_NAME,
    sessionName: _ => process.env.NODE_ENV === 'production' ? window.sessionName : process.env.REACT_APP_SESSION_NAME,
    sessionError: _ => process.env.NODE_ENV === 'production' ? window.sessionError : process.env.REACT_APP_SESSION_ERROR,
    apiUrl: ({ url = '' } = {}) => (process.env.NODE_ENV === 'production' ? window.apiUrl : process.env.REACT_APP_API_URL) + url,
    appUrl: ({ url = '' } = {}) => (process.env.NODE_ENV === 'production' ? window.dashboardUrl : process.env.REACT_APP_DASHBOARD_URL) + url,
    resourcesUrl: ({ url = '' } = {}) => (process.env.NODE_ENV === 'production' ? window.assetsUrl : process.env.REACT_APP_RESOURCES_URL) + url,
    systemType: process.env.NODE_ENV === 'production' ? window.systemType : 'DEV',
    isDeveloperWeb: _ => {
        return Helpers.config.systemType === 'DEVELOPERPERU'
    },
    isCustomers: _ => {
        return Helpers.config.systemType === 'CUSTOMERS'
    },
    verifyToken: _ => {
        if (Helpers.config.isProduction) {
            if (Helpers.config.isCustomers()) {
                if (Helpers.date.get({ format: '%Y-%m-%d' }) >= atob('MjAyMi0xMi0wMQ==')) { // 2022-12-01 - no usado
                return false
                }
            } else {
                if (!Helpers.config.isDeveloperWeb()) {
                return false
                }
            }
        }

        return true
    },
    devInformation: _ => {
        console.log('%cCARLOS ESTHEFANO HUAMAN MARCOS', 'color: RED; font-size: 20px; font-weight: bold');
        console.log('%cDESARROLLADOR DE SOFTWARE', 'color: RED; font-size: 15px; font-weight: bold');
        console.log('%cPERU', 'color: RED; font-size: 15px; font-weight: bold');
        console.log('%cWHATSAPP +51 989998352', 'color: RED; font-size: 15px; font-weight: bold');
        console.log('%cEMAIL carhuaman1995@gmail.com', 'color: RED; font-size: 15px; font-weight: bold');
    }
}

Helpers.axios = ({ method = 'GET', url = '', data = {}, hasFile = false }) => {
    let urlReq = method === 'GET' ? url + '?jwt=' + Helpers.localStorage.getJWT() : url
    let bodyReq
    if (method === 'POST') {
        if (hasFile) {
            bodyReq = data
            bodyReq.append('jwt', Helpers.localStorage.getJWT())
        } else {
            bodyReq = { ...data }
            bodyReq.jwt = Helpers.localStorage.getJWT()
        }
    }
    let config = {
        method,
        url: Helpers.config.apiUrl({ url: urlReq }),
        data: bodyReq
    }

    if (hasFile) {
        config.headers = { 'content-type': 'multipart/form-data' }
    }

    return new Promise((resolve, reject) => {
        axios(config).then(res => {
            return res.data
        }).then(res => {
            if (res.message === Helpers.config.sessionError()) {
                localStorage.clear()
                window.location.href = Helpers.config.appUrl({ url: '/' })
                return
            }
            if (res.hasOwnProperty('data')) {
                resolve(res)
            } else {
                reject({
                    response: 'danger',
                    message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.'
                })
            }
        }).catch(req => {
            reject({
                response: 'danger',
                message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.'
            })
        })
    })
}

Helpers.promise = {}
Helpers.promise.catch = ({ req = {}, toast = {} }) => {
    if (req.response) {
        Helpers.toast.construct({ response: req.response, message: req.message, toast })
    } else {
        Helpers.toast.construct({ response: 'error', message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.', toast })
    }
}

Helpers.toast = {
    options: () => {
        return {
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: 0.2,
        }
    },
    construct: ({ response = '', message = '', toast = {} }) => {
        if (response === 'success') {
            Helpers.toast.success({ message, toast })
        } else if (response === 'info') {
            Helpers.toast.info({ message, toast })
        } else if (response === 'warning') {
            Helpers.toast.warning({ message, toast })
        } else {
            Helpers.toast.error({ message, toast })
        }
    },
    success: ({ message = '', toast = {} }) => {
        toast.success(message, '', Helpers.toast.options())
    },
    info: ({ message = '', toast = {} }) => {
        toast.info(message, '', Helpers.toast.options())
    },
    warning: ({ message = '', toast = {} }) => {
        toast.warning(message, '', Helpers.toast.options())
    },
    error: ({ message = '', toast = {} }) => {
        toast.error(message, '', Helpers.toast.options())
    }
}

Helpers.localStorage = {
    getSession: () => JSON.parse(localStorage.getItem(Helpers.config.sessionName())),
    exists: () => localStorage.getItem(Helpers.config.sessionName()),
    existsJWT: () => {
        if (Helpers.localStorage.exists()) {
            if (Helpers.localStorage.getSession()?.jwt) {
                return true
            }
        }
        return false
    },
    setSession: (session) => localStorage.setItem(Helpers.config.sessionName(), JSON.stringify(session)),
    getJWT: () => Helpers.localStorage.existsJWT() ? Helpers.localStorage.getSession()?.jwt : ''
}

Helpers.components = {
    state: ({ state, textZero = 'INACTIVO', textOne = 'ACTIVO', type = 'text' }) => {
        let opt = {
            color: '',
            text: ''
        }

        let options = [
            { value: 0, color: 'danger', text: textZero },
            { value: 1, color: 'success', text: textOne },
        ]

        let find = options.find(o => parseInt(o.value) === state)
        if (find) {
            opt = find
        }

        return <strong className={`${type === 'text' ? 'text' : (type === 'badge' ? 'badge badge' : '')}-${opt.color}`}>
            {opt.text}
        </strong>
    }
}

Helpers.formData = {
    generate: ({ data, file = [] }) => {
        let formData = new FormData()

        for (const key in data) {
            let fileFinded = file.find(f => f.name === key)
            formData.append(key, fileFinded ? (fileFinded?.value ? fileFinded.value : '') : (
                parseInt(data[key]) === 0 || data[key] ? data[key] : ''
            ))
        }
        return formData
    }
}

Helpers.date = {
    get: ({ format = '%Y-%m-%d', addDays = 0 } = {}) => {
        let date = new Date()
        if (addDays !== 0) {
            date.setDate(date.getDate() + addDays)
        }

        let d = {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate(),
            hours: date.getHours(),
            minutes: date.getMinutes(),
            second: date.getSeconds()
        }
        let dateTex = format.replace('%Y', d.year)
            .replace('%m', (d.month + 1 < 10 ? '0' : '') + (d.month + 1))
            .replace('%d', (d.day < 10 ? '0' : '') + d.day)
            .replace('%H', (d.hours < 10 ? '0' : '') + d.hours)
            .replace('%i', (d.minutes < 10 ? '0' : '') + d.minutes)
            .replace('%s', (d.second < 10 ? '0' : '') + d.second)
            .replace('%a', d.hours < 12 ? 'AM' : 'PM')

        return dateTex
    }
}

Helpers.number = {
    withCommas: ({ number }) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}



export default Helpers