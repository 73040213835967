import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Helpers from '@components/Helpers/Helpers'
import Login from '@views/Auth/Login'
import { useSelector } from 'react-redux'
import Theme from '@views/Theme/Theme'
import MaintenanceCustom from '@views/Maintenance/MaintenanceCustom'
import { NotFound } from 'react-theme-bootstrap'
import Product from '@views/Product/Product'
import BranchOffice from '@views/BranchOffice/BranchOffice'
import TagsItems from '@views/TagsItems/TagsItems'
import Profile from '@views/Profile/Profile'
import Worker from '@views/Worker/Worker'
import Customers from '@views/Customers/Customers'
import Sale from '@views/Sale/Sale'
import Dashboard from '@views/Dashboard/Dashboard'
import { Link } from 'react-router-dom'

const Routes = ({ type }) => {
  const store = useSelector(store => store.session)

  const hasPermission = (page) => {
    let permissions = store.permissions
    
    let has = false

    permissions.forEach(tpm => {
      if (has) {
        return false
      }

      tpm.childrens.forEach(tp => {
        if (has) {
          return false
        }

        tp.childrens.forEach(tp => {
          if (has) {
            return false
          }

          if (tp.a_href === page) {
            has = true
          }
        })
      })
    })

    return has
  }

  if (type === 'auth') {
    return <BrowserRouter>
      <Switch>
        <Route exact path={Helpers.config.appUrl({ url: '/login' })}>
          <Login />
        </Route>
        <Route exact path={Helpers.config.appUrl({ url: '/' })}>
          <Login />
        </Route>
        <Route>
          <Redirect to={Helpers.config.appUrl({ url: '/' })} />
        </Route>
      </Switch>
    </BrowserRouter>
  }

  return <BrowserRouter>
    <Switch>
      <Route path={Helpers.config.appUrl({ url: '/login' })} exact>
        <Redirect to={Helpers.config.appUrl({ url: '/' + store.main_profile })} />
      </Route>
      <Route path={Helpers.config.appUrl({ url: '/' })} exact>
        <Redirect to={Helpers.config.appUrl({ url: '/' + store.main_profile })} />
      </Route>
      {hasPermission('privilegios') ? <Route path={Helpers.config.appUrl({ url: '/privilegios' })} exact>
        <Theme title="Privilegios" page="privilegios">
          <TagsItems />
        </Theme>
      </Route> : ''}
      {hasPermission('roles') ? <Route path={Helpers.config.appUrl({ url: '/roles' })} exact>
        <Theme title="Roles" page="roles">
          <Profile showFirstLevel={false} />
        </Theme>
      </Route> : ''}
      {hasPermission('usuarios') ? <Route path={Helpers.config.appUrl({ url: '/usuarios' })} exact>
        <Theme title="Usuarios" page="usuarios">
          <Worker />
        </Theme>
      </Route> : ''}
      {hasPermission('dashboard') ? <Route path={Helpers.config.appUrl({ url: '/dashboard' })} exact>
        <Theme title="Dashboard" page="dashboard">
          <Dashboard />
        </Theme>
      </Route> : ''}
      {hasPermission('tipo-cliente') ? <Route path={Helpers.config.appUrl({ url: '/tipo-cliente' })} exact>
        <Theme title="Tipos de Cliente" page="tipo-cliente">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Tipos de Clientes
          </>} namePlural="Tipos de Cliente" nameSingle="Tipo de Cliente" separator="el" module="type_customers"
            removeEnabled
            removeId
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_type_customers', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              }
            }}
          />
        </Theme>
      </Route> : ''}
      {hasPermission('categorias') ? <Route path={Helpers.config.appUrl({ url: '/categorias' })} exact>
        <Theme title="Categorías" page="categorias">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Categorías
          </>}
            namePlural="Categorías" nameSingle="Categoría" separator="el" module="category"
            removeId
            removeEnabled
            tableFieldsAdd={[
              {
                name: 'code_category', align: 'center', text: 'Código', type: 'input', options: {
                  maxLength: 80,
                  required: true,
                  classNameParent: 'col-12 mb-2',
                  icon: '',
                  size: ''
                }
              },
            ]}
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_category', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              }
            }}
          />
        </Theme>
      </Route> : ''}
      {hasPermission('unidad-de-medida') ? <Route path={Helpers.config.appUrl({ url: '/unidad-de-medida' })} exact>
        <Theme title="Unidad de Medida" page="unidad-de-medida">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Unidad de Medida
          </>}
            namePlural="Unidad de Medida" nameSingle="Unidad de Medida" separator="el" module="unit_measure"
            removeId
            removeEnabled
            tableFieldsAdd={[
              {
                name: 'code_unit_measure', align: 'center', text: 'Código', type: 'input', options: {
                  maxLength: 80,
                  required: true,
                  classNameParent: 'col-12 mb-2',
                  icon: '',
                  size: ''
                }
              },
            ]}
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_unit_measure', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              }
            }}
          />
        </Theme>
      </Route> : ''}
      {hasPermission('productos') ? <Route path={Helpers.config.appUrl({ url: '/productos' })} exact>
        <Theme title="Gestión de Productos" page="productos">
          <Product />
        </Theme>
      </Route> : ''}
      {hasPermission('sucursales') ? <Route path={Helpers.config.appUrl({ url: '/sucursales' })} exact>
        <Theme title="Gestión de Sucursales" page="sucursales">
          <BranchOffice />
        </Theme>
      </Route> : ''}
      {hasPermission('clientes') ? <Route path={Helpers.config.appUrl({ url: '/clientes' })} exact>
        <Theme title="Gestión de Clientes" page="clientes">
          <Customers />
        </Theme>
      </Route> : ''}
      {hasPermission('gastos') ? <Route path={Helpers.config.appUrl({ url: '/gastos' })} exact>
        <Theme title="Gastos" page="gastos">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Gastos
          </>}
            namePlural="Gastos" nameSingle="Gastos" separator="el" module="expenses"
            removeEnabled
            tableFieldsAdd={[
              {
                name: 'observation_expenses', align: 'center', text: 'Observacion', type: 'textarea', options: {
                  maxLength: 255,
                  required: true,
                  classNameParent: 'col-12 mb-2',
                  icon: '',
                  size: '',
                  rows: 5
                }
              },
              {
                name: 'amount_expenses', align: 'center', text: 'Monto', type: 'input', options: {
                  required: true,
                  classNameParent: 'col-12 mb-2',
                  icon: '',
                  size: '',
                  type: 'number',
                  min: '0.01',
                  step: '0.01'
                },
                render: r => <div className="text-primary"><strong>$ {r.amount_expenses}</strong></div>
              },
              {
                name: 'name_expenses', align: 'center', text: 'Hecho Por', type: 'input', options: {
                  maxLength: 255,
                  required: true,
                  classNameParent: 'col-12 mb-2',
                  icon: '',
                  size: '',
                  rows: 5
                }
              },
            ]}
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_unit_measure', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              },
              dataStart: [
                { name: 'observation_expenses', value: '' },
                { name: 'amount_expenses', value: '' },
                { name: 'name_expenses', value: '' },
                { name: 'enabled_expenses', value: 1 },
              ]
            }}
          />
        </Theme>
      </Route> : ''}
      {hasPermission('ventas') ? <Route path={Helpers.config.appUrl({ url: '/ventas' })} exact>
        <Theme title="Ventas" page="ventas">
          <Sale />
        </Theme>
      </Route> : ''}
      {hasPermission('paises') ? <Route path={Helpers.config.appUrl({ url: '/paises' })} exact>
        <Theme title="Países" page="paises">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Países
          </>}
            namePlural="Países" nameSingle="País" separator="el" module="country"
            removeId
            removeEnabled
            tableFieldsAdd={[
              {
                name: 'code_country', align: 'center', text: 'Código', type: 'input', options: {
                  maxLength: 80,
                  required: true,
                  classNameParent: 'col-12 mb-2',
                  icon: '',
                  size: ''
                }
              },
            ]}
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_country', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              }
            }}
          />
        </Theme>
      </Route> : ''}
      {hasPermission('formas-de-pago') ? <Route path={Helpers.config.appUrl({ url: '/formas-de-pago' })} exact>
        <Theme title="Formas de Pago" page="formas-de-pago">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Formas de Pago
          </>}
            namePlural="Formas de Pago" nameSingle="Forma de Pago" separator="la" module="format_pay"
            removeId
            removeEnabled
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_format_pay', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              }
            }}
          />
        </Theme>
      </Route> : ''}
      {hasPermission('metodos-de-pago') ? <Route path={Helpers.config.appUrl({ url: '/metodos-de-pago' })} exact>
        <Theme title="Métodos de Pago" page="metodos-de-pago">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Métodos de Pago
          </>}
            namePlural="Métodos de Pago" nameSingle="Método de Pago" separator="la" module="method_pay"
            removeId
            removeEnabled
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_method_pay', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              }
            }}
          />
        </Theme>
      </Route> : ''}
      {hasPermission('uso-cdfi') ? <Route path={Helpers.config.appUrl({ url: '/uso-cdfi' })} exact>
        <Theme title="Uso CDFI" page="uso-cdfi">
          <MaintenanceCustom title={<>
            <i className="fa fa-list text-secondary mr-2"></i>Listado de Uso CDFI
          </>}
            namePlural="Uso CDFI" nameSingle="Uso CDFI" separator="la" module="cdfi"
            removeId
            removeEnabled
            optionsView={{
              removeEnabled: true,
              dataStart: [{ name: 'enabled_cdfi', value: 1 }],
              inputDescriptionOptions: {
                icon: '',
                size: ''
              }
            }}
          />
        </Theme>
      </Route> : ''}
      <Route>
        <Theme title="No existe la página" permissions={store.permissions} showBread={false}>
          <NotFound
            Link={Link}
            backUrl={Helpers.config.appUrl()}
            imageNotFound={Helpers.config.resourcesUrl({ url: '/images/dashboard/not-found.png' })}
            styleImage={{
              maxHeight: '200px'
            }}
          />
        </Theme>
      </Route>
    </Switch>
  </BrowserRouter>
}

Routes.defaultProps = {
  type: 'session'
}

export default Routes