/* import React from 'react' */
/* import { Auth } from 'react-theme-bootstrap' */
import Helpers from '@components/Helpers/Helpers'
import Controllers from '@components/Controllers/Controllers'
import { toast } from 'react-toastify'


import React, { Fragment, useState } from 'react'

const Auth = ({ brand, brandTop, btnColor, iconUser, iconPassword, fnLogin }) => {
    const [session, setSession] = useState({
        p_username: Helpers.config.isDeveloperWeb() ? 'administrador' : '',
        p_password: Helpers.config.isDeveloperWeb() ? '1234' : ''
    })

    const [loadSubmit, setLoadSubmit] = useState(false)

    const handleSubmit = e => {
        e.preventDefault()
        let $this = e.target

        if (!$this.checkValidity()) {
            $this.classList.add('was-validated')
        } else {
            $this.classList.remove('was-validated')

            setLoadSubmit(true)
            fnLogin({
                ...session,
                always: _ => {
                    setLoadSubmit(false)
                }
            })
        }
    }

    return <div className="head-dash-login">
        <div className="head-dash-login-box">
            <div className="col-12 text-center mb-4">
                <img src={brandTop} className="head-dash-login-logo-top" alt="" />
            </div>
            <div className="bg-white form-row head-dash-login-content">
                <div className="col-12 text-center mb-4">
                    <img src={brand} className="head-dash-login-logo" alt="" />
                </div>
                <form autoComplete="off" onSubmit={handleSubmit} className="col-12 needs-validation" noValidate>
                    <div className="form-row">
                        <div className="col-12 mb-2">
                            <strong className="text-secondary">ACCESAR</strong>
                        </div>
                        <div className="col-12 mb-2">
                            <small className="text-secondary font-weight-bold">Correo</small>
                            <div className="input-group">
                                {/* <div className="input-group-prepend">
                                    <div className="input-group-text"><i className={iconUser}></i></div>
                                </div> */}
                                <input required type="text" defaultValue={session.p_username} className="form-control form-control-without-border" onChange={e => setSession({ ...session, p_username: e.currentTarget.value })} />
                                <div className="invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <small className="text-secondary font-weight-bold">Contraseña</small>
                            <div className="input-group">
                                {/* <div className="input-group-prepend">
                                    <div className="input-group-text"><i className={iconPassword}></i></div>
                                </div> */}
                                <input required type="password" defaultValue={session.p_password} className="form-control form-control-without-border" onChange={e => setSession({ ...session, p_password: e.currentTarget.value })} />
                                <div className="invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" id="remember_me" className="custom-control-input" />
                                <label title="" htmlFor="remember_me" className="custom-control-label">Recordarme</label>
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-md-end">
                            <a className="text-secondary">¿Has olvidado tu contraseña?</a>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="d-flex justify-content-end">
                                <button type="submit" className={'btn btn-' + btnColor}>
                                    {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <Fragment>
                                        &nbsp;&nbsp;&nbsp;INGRESAR&nbsp;&nbsp;&nbsp;
                                    </Fragment>}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
}

Auth.defaultProps = {
    brand: '',
    brandTop: '',
    btnColor: 'success',
    iconUser: 'fa fa-user',
    iconPassword: 'fa fa-lock',
    fnLogin: () => {}
}

/* export default Auth */

const Login = _ => {
    return <Auth
        btnColor="primary"
        brand={Helpers.config.resourcesUrl({ url: '/images/dashboard/brand-dashboard-icsitum.png' })}
        brandTop={Helpers.config.resourcesUrl({ url: '/images/dashboard/brand-auth.png' })}
        fnLogin={({ always, p_username, p_password }) => {
            Controllers.users.get_login({
                p_username,
                p_password
            }).then(res => {
                Helpers.toast.construct({ ...res, toast })
                if (res.response === 'success') {
                    Helpers.localStorage.setSession({
                        jwt: res.data.jwt
                    });
                    window.location.href = Helpers.config.appUrl({ url: '/' });
                }
            }).catch(req => {
                Helpers.promise.catch({ req, toast });
            }).finally(_ => always())
        }}
    />
}

export default Login